import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { CurrencyProvider } from './components/currency/currencyContext';

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CurrencyProvider >
     <I18nextProvider i18n={i18next}>
    <App />
     </I18nextProvider>
     </CurrencyProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();